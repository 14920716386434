<template>
  <tenant-settings-provider v-slot="{ fetching, saving, save, settings }">
    <div v-if="fetching">
      Loading...
    </div>
    <div v-else>
      <div class="d-flex mb-8">
        <h2 class="text-h3">
          Разное
        </h2>
        <VBtn
          class="ml-auto"
          color="success"
          :disabled="saving"
          @click="() => save(settings)"
        >
          {{ saving ? "Save..." : 'Save' }}
        </VBtn>
      </div>

      <div v-if="settings.features.practices">
        <div class="text-h5 mb-2">
          Практики
        </div>
        <div class="d-flex align-center">
          <span>
            на главной странице
          </span>
          <v-switch
            hide-details
            dense
            class="ml-4 mb-2"
            v-model="settings.features.practices.enabled"
            :label="settings.features.practices.enabled ? 'Активны' : 'Скрыты'"
          />
        </div>
      </div>

      <v-divider class="mt-8 mb-8" />

      <div class="text-h5">
        Изображения
      </div>
      <splash-image v-model="settings.imageOnMain" />
    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '@/components/Tenant/TenantSettingsProvider.vue'
import { SplashImage } from '@/widgets/splash/ui'

export default {
  components: {
    TenantSettingsProvider,
    SplashImage
  }
}
</script>
