<template>
  <div>
    <v-row>
      <v-col>
        <div>
          Картинка внизу страницы
        </div>
        <ImagePicker
          :ratio="ratio"
          :value="value"
          @input="(e) => $emit('input', e)"
        />
        <ImageRequirements :ratio="`${ratio}`" size="2880px" />
      </v-col>
      <v-col cols="3">
        <div>
          Превью
        </div>
        <SplashImagePreview
          :image="value ? value.url : null"
          :ratio="ratio"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ImagePicker, ImageRequirements } from '@/shared/ui'
  import SplashImagePreview from './SplashImagePreview.vue';
  const ratio = 0.1666

  export default {
    components: {
      SplashImagePreview,
      ImageRequirements,
      ImagePicker,
    },
    props: {
      value: {
        type: Object,
      }
    },
    data () {
      return {
        ratio
      }
    }
  }
</script>
