<template>
  <div class="sip" :style="{ backgroundImage: pageImage }">
    <RatioSpan
      :ratio="0.89"
    />
    <div
      class="sip__image"
      :style="{ backgroundImage: splashImage }"
    >
      <RatioSpan :ratio="ratio"  />
    </div>
  </div>
</template>

<script>
  import PageImage from './preview.png'
  import { ImagePicker, ImageRequirements, RatioSpan } from '@/shared/ui'

  export default {
    props: {
      image: {
        type: String
      },
      ratio: {
        type: Number,
      }
    },
    components: {
      RatioSpan,
      ImageRequirements,
      ImagePicker,
    },
    computed: {
      splashImage () {
        return `url(${this.image})`
      },
      pageImage () {
        return `url(${PageImage})`
      }
    }
  }
</script>

<style scoped>
.sip {
  position: relative;
  background-size: cover;
  border: 1px solid #eee;
}
.sip__image {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
}
</style>
