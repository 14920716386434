<template>
  <image-picker
    v-bind="{
      ...$attrs,
      src: src
    }"
    v-on="{
      ...$listeners,
      choose,
      remove
    }"
  />
</template>

<script>
import ImagePicker from '@/components/elements/image-picker.vue'

export default {
  components: {
    ImagePicker
  },
  props: {
    value: {
      type: Object,
    }
  },
  computed: {
    src () {
      return this.value?.url
    },
  },
  methods: {
    choose () {
      this
        .openPhotoModal()
        .$once('choose', (photo) => {
          if (photo?.url) {
            this.$emit('input', photo)
          }
        })
    },
    remove () {
      if (!confirm('Remove?')) return
      this.$emit('input', null)
    }
  }
}
</script>
